import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import dayjs from "~/utils/tools/dayjs";
import useBaseUtils from "~/functions/useBaseUtils";
import { dontConvertIds } from "~/utils/ciq";
import { dontConvertTikrKeys as dontConvertFmpTikrKeys } from "~/utils/fmp";
import { dontConvertTikrKeys as dontConvertMorningstarTikrKeys } from "~/utils/morningstar";
export default {
  __name: 'FinValueTblCell',
  props: {
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredunits: {
      type: Number,
      "default": 1
    },
    formatclass: {
      type: String,
      "default": "black--text"
    },
    // this isn't right, dependent upon darkmode
    period: {
      type: String,
      "default": "a"
    },
    decimals: {
      type: Number,
      "default": 2
    },
    exrate: {
      type: Number,
      "default": 1
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    showturns: {
      type: Boolean,
      "default": true
    },
    segments: {
      type: Boolean,
      "default": false
    },
    dateObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    latestDate: {
      type: String,
      "default": "2023-12-31T00:00:00.000Z"
    },
    dataSource: {
      type: String,
      "default": "ciq"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      specialUser = _useBaseUtils.specialUser;
    var createNumberFormatter = function createNumberFormatter(decimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };
    var formatNumber = computed(function () {
      return createNumberFormatter(props.decimals);
    });
    var print = function print(result) {
      return "".concat(result > 0 ? "".concat(formatNumber.value.format(result)) : "(".concat(formatNumber.value.format(Math.abs(result)), ")"));
    };
    var outsideCutoff = computed(function () {
      var currentDate = dayjs(props.dateObj.fiperiodenddate);
      var cutoffDate = dayjs(props.latestDate); // Three year cut off for semi annual and quarterly
      if (["semi", "q"].includes(props.period)) {
        return currentDate.diff(cutoffDate, "year", true) <= -10;
      }
      return currentDate.diff(cutoffDate, "year", true) <= -10;
    });
    var cellValue = computed(function () {
      /**
      * 'data' = {v: 'number stored as string', u: 'unittypeid of value key'}
      * 'unittypeid' = units of 'data'  0: Actual scale they were reported, 1: Thousands, 2: Millions
      * only financial numbers should be going through this function, so they should all be numbers stored as strings
      * 'exrate' = currencyRate / US Dollar
      * 'desiredUnits' = desired units to return 0: thousands, 1: millions, 2: billions
      * 'decimals' = # of decimal points to return
      */
      var data = props.dataObj;
      if (!data) return "";
      var exrate = props.exrate || 1;
      var desiredUnits = props.desiredunits;
      var lineitem = props.lineitem;
      var currencyToggle = props.currencytoggle;
      var showTurns = props.showturns;
      var multiplyby = lineitem.multiplyby || 1;
      var result = parseFloat(data.v) * multiplyby;
      var unittypeid = data.u;
      if (!result) return ""; // if unittypeid = 0 the number is a % or ratio so currency conversion unit conversion does not apply
      if (unittypeid === 0 && !props.segments) {
        if (lineitem.formula === "turns") {
          var turnsSuffix = showTurns ? "x" : "";
          var formattedResult = formatNumber.value.format(Math.abs(result));
          return result > 0 ? "".concat(formattedResult).concat(turnsSuffix) : "(".concat(formattedResult).concat(turnsSuffix, ")");
        }
        if (currencyToggle === 1 && !dontConvertIds.includes(lineitem.dataitemid) && !dontConvertFmpTikrKeys.includes(lineitem.tikrKey) && !dontConvertMorningstarTikrKeys.includes(lineitem.tikrKey)) {
          result = result / exrate;
        }
        return print(result);
      }
      if (currencyToggle === 1 && !dontConvertIds.includes(lineitem.dataitemid) && !dontConvertFmpTikrKeys.includes(lineitem.tikrKey) && !dontConvertMorningstarTikrKeys.includes(lineitem.tikrKey)) {
        result = result / exrate;
      }
      var unitConversion = unittypeid - (desiredUnits + 1);
      switch (unitConversion) {
        case -3:
          // multiply 1e-6
          return print(result / 1000000000);
        case -2:
          // multiply 1e-6
          return print(result / 1000000);
        case -1:
          // multiply by 1e-3
          return print(result / 1000);
        case 0:
          // multiply by 1
          return print(result);
        case 1:
          // 1 e3
          return print(result * 1000);
        default:
          if (dev.value) {
            console.error("Converting Units Logic Error: ", unitConversion);
          }
          return "";
      }
    });
    var hideNumberFromCell = computed(function () {
      if (props.dataSource === "morningstar") {
        var _props$lineitem;
        var dateKey = props.dateObj.dateKey;
        var cellObject = ((_props$lineitem = props.lineitem) === null || _props$lineitem === void 0 ? void 0 : _props$lineitem[dateKey]) || {};
        if (dateKey) {
          return cellObject.chartIcon === true;
        }
      }
      return !specialUser.value && outsideCutoff.value;
    });
    return {
      __sfc: true,
      props: props,
      dev: dev,
      specialUser: specialUser,
      createNumberFormatter: createNumberFormatter,
      formatNumber: formatNumber,
      print: print,
      outsideCutoff: outsideCutoff,
      cellValue: cellValue,
      hideNumberFromCell: hideNumberFromCell
    };
  }
};