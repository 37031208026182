const dontConvertTikrKeys = [
  "EarningReports:dilutedAverageShares",
  "EarningReports:basicAverageShares",
  "OperationRatios:cashConversionCycle",
  "OperationRatios:daysInSales",
  "OperationRatios:daysInInventory",
  "OperationRatios:daysInPayment",
]

export { dontConvertTikrKeys }
